<template>
  <xt_vcontainer gutter_row="24px" class="_me_content">
    <xt_hcontainer class="_header_container">
      <img class="_avatar" :src="require('@/assets/imgs/logo/portrait.png')" />
      <xt_vcontainer>
        <div class="headline2">Steven (Jiaxun) Tang</div>
        <div>Work Location: USA</div>
      </xt_vcontainer>
    </xt_hcontainer>
    <div class="self_Intro" lang="en">
      Steven is an operating system researcher and an experienced software engineer. <br/>
      He is currently a Ph.D. candidate at UMass Amherst. <br/>
      He is enthusiastic about exploring the frontiers of software technology and building useful tools and products for users. He is passionate about his research and work, always enjoying the process of self-improvement, and loves to work in exceptional teams.
    </div>

    <h2 class="col-esm-4 col-sm2-8 col-lg-12 headline3">Research Expertise</h2>
    <xt_hcontainer gutter_row="24px">
      <!-- <xt_vcontainer>
        <div class="headline4">Languages</div>
        <xt_list>
          <xt_list_item
            class="col-esm-2 col-sm1-2 col-lg-6"
            v-for="language in languages"
            :key="language"
          >
            {{ language }}
          </xt_list_item>
        </xt_list>
      </xt_vcontainer>

      <xt_vcontainer>
        <div class="headline4">Tools/Frameworks</div>
        <xt_list>
          <xt_list_item
            class="col-esm-2 col-sm1-2 col-lg-3"
            v-for="framework in frameworks"
            :key="framework"
          >
            {{ framework }}
          </xt_list_item>
        </xt_list>
      </xt_vcontainer> -->

      <xt_vcontainer class="col-lg-12">
        <!-- <div class="headline4">Research Expertise</div> -->
        <xt_vcontainer>
          <div>Machine learning system and software system performance profiling and optimization</div>
        </xt_vcontainer>

        <!-- <xt_vcontainer class="col-lg-12">
          <div class="headline4">Engineering Skills</div>
          <xt_list>
            <xt_list_item
              class="col-esm-4 col-sm1-4 col-lg-6"
              v-for="skill in skills"
              :key="skill"
            >
              {{ skill }}
            </xt_list_item>
          </xt_list>
        </xt_vcontainer> -->
      </xt_vcontainer>
    </xt_hcontainer>
    <h2 class="col-esm-4 col-sm1-8 col-lg-12 _category headline3">
      Experience
    </h2>

    <xt_hcontainer class="experience_container">
      <xt_experience_card
        v-for="experience in experiences"
        :key="experience.position"
        :data="experience"
      ></xt_experience_card>
    </xt_hcontainer>

    <h2 class="col-esm-4 col-sm2-8 col-lg-12 _category headline3">
      Featured Projects
    </h2>

    <xt_hcontainer class="project_container">
      <xt_project_card
        class="project_card"
        v-for="project in projects"
        :key="project.name"
        :data="project"
      ></xt_project_card>
    </xt_hcontainer>

    <xt_hcontainer class="project_findmore_container" gutter_row="12px">
      <xt_rect_btn href="/lab" class="project_findmore_btn col-esm-4"
        >More interesting projects</xt_rect_btn
      >
      <xt_rect_btn
        href="https://scholar.google.com/citations?user=Bdej4vwAAAAJ&hl=en"
        class="project_findmore_btn  col-esm-4"
        >All publications</xt_rect_btn
      >
    </xt_hcontainer>

    <h2 class="col-esm-4 col-sm2-8 col-lg-12 _category headline3">Patents</h2>
    <xt_list>
      <xt_list_item
        class="col-esm-4 col-sm2-8 col-lg-12"
        v-for="patent in patents"
        :key="patent"
      >
        {{ patent }}
      </xt_list_item>
    </xt_list>

    <h2 class="col-esm-4 col-sm2-8 col-lg-12 _category headline3">Awards</h2>

    <xt_list>
      <xt_list_item
        class="col-esm-4 col-sm2-8 col-lg-12"
        v-for="award in awards"
        :key="award"
      >
        {{ award }}
      </xt_list_item>
    </xt_list>

    <h2 class="col-esm-4 col-sm2-8 col-lg-12 _category headline3">Education</h2>
    <xt_hcontainer class="experience_container">
      <xt_experience_card
        v-for="experience in educations"
        :key="experience.position"
        :data="experience"
      ></xt_experience_card>
    </xt_hcontainer>
  </xt_vcontainer>
</template>

<script setup>
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import { reactive } from "vue";
import xt_list from "@/components/lists/xt_list.vue";
import xt_list_item from "@/components/lists/xt_list_item.vue";
import xt_experience_card from "@/components/card/xt_experience_card.vue";
import xt_project_card from "@/components/card/xt_project_card.vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";

// import xt_article_card_brief from "@/components/card/xt_article_card_brief.vue";
const projects = reactive([
  {
    name: "MLInsight: Hierarchical Performance and Memory Analysis for ML Programs",
    desc: "A lightweight, code-change-free profiler that can diagnose GPU memory-related performance problems.  Joint work with ByteDance.",
    authors: [
      { name: "Steven (Jiaxun) Tang", isMe: true },
      { name: "......", isMe: false },
    ],
    links: {code: "https://github.com/bytedance/MLInsight"},
    publisher: "In Progress",
  },
  {
    name: "Scaler: Efficient and Effective Cross Flow Analysis",
    desc: "We built a light-weight profiler that achieves high data collection frequency (~700x more frequent than perf), low-performance overhead (~190x lower than bpftrace), and low memory overhead (~7x lower than perf) at the same time while being able to reveal performance problems effectively through the proposed cross-flow analysis method. Joint work with ByteDance.",
    authors: [
      { name: "Steven (Jiaxun) Tang", isMe: true },
      { name: "Mingcan Xiang", isMe: false },
      { name: "Yang Wang", isMe: false },
      { name: "Bo Wu", isMe: false },
      { name: "Jianjun Chen", isMe: false },
      { name: "Tongping Liu", isMe: false },
    ],
    links: {code: "https://github.com/UTSASRG/Scaler", paper: "https://arxiv.org/abs/2409.00854"},
    publisher: "ASE'24",
  },
  {
    name: "CachePerf: A Unified Cache Miss Classifier via Hybrid Hardware Sampling",
    desc: "We built a profiler that correctly identifies different cache misses (false sharing/conflict miss/capacity miss) in one tool while maintaining low-performance overhead (14%).",
    authors: [
      { name: "Jin Zhou", isMe: false },
      { name: "Steven (Jiaxun) Tang", isMe: true },
      { name: "Hanmei Yang", isMe: false },
      { name: "Tongping Liu", isMe: false },
    ],
    links: { paper: "https://dl.acm.org/doi/abs/10.1145/3489048.3526954" },
    publisher: "Sigmetrics'22",
  },
  {
    name: "Machine learning to assist filtered two‐fluid model development for dense gas–particle flows",
    desc: "Trained machine learning model to improve CFD simulation model to balance accuracy and efficiency. Designed and developed a data linker to efficiently integrate the developed model into FLUENT simulation software.",
    authors: [
      { name: "LiTao Zhu", isMe: false },
      { name: "Steven (Jiaxun) Tang", isMe: true },
      { name: "ZhengHong Luo", isMe: false },
    ],
    links: {
      paper: "https://aiche.onlinelibrary.wiley.com/doi/full/10.1002/aic.16973",
    },
    publisher: "AIChE'20",
  },
  {
    name: "High-precision cable sheath measurement instrument",
    desc: "Designed and developed half of the computer vision algorithms to automatically perform quality inspection of electric cables. Guang Dong Nan Yang Cable Holding Co., Ltd purchased the system.",
    authors: [
      { name: "Steven (Jiaxun) Tang", isMe: true },
      { name: "Xiang Liu", isMe: false },
    ],
    links: {},
    publisher: "Commercial Project",
  },
  {
    name: "XtTech Cloud",
    desc: "A self-developed hybrid cloud solution based on various open-source technologies. The backbone of XtTech. It provides robust and reliable data storage & backup, web hosting, virtualization, performance monitoring, and other services. This website is also a part of XtTech Cloud.",
    authors: [{ name: "Steven (Jiaxun) Tang", isMe: true }],

    links: {url: "https://xttechgroup.com/status"},
    publisher: "Amateur Project",
  },
  {
    name: "Mass Lab IT Infrastructure",
    desc: "A cluster of services providing unified lab machine access securely to users. Provides two factor authentication-based SSH login, web-based remote desktop, network storage, uptime monitoring, and security auditing capability. Serves Mass Lab, Prof. Hui Guan, Prof. Xuan Zhang, and Prof. Sandip Kundu's research group.",
    authors: [{ name: "Steven (Jiaxun) Tang", isMe: true }],
    links: {url: "https://masslab.xttech.top"},
    publisher: "Amateur Project",
  },
  {
    name: "ECE670 Autograder",
    desc: "A self-implemented project autograder for the Advanced System Software Design at UMASS Amherst (ECE670). It has code judgers, a Python plugin system, email feedback & monitoring mechanisms, and an OTA update mechanism. The system served 25+ students for over three months.",
    authors: [{ name: "Steven (Jiaxun) Tang", isMe: true }],

    links: {},
    publisher: "Amateur Project",
  },
  {
    name: "XtTech Mobile Office",
    desc: "A self-modified SUV with a monitor, a low-cost 5G/Starlink aggregated network based on mptcp, a parking air conditioner/heater, and a 2kWh battery that charges in-motion. It functions as an office/studio operable in any weather condition while maintaining the appearance of a normal vehicle. Suitable for both everyday use and adventure.",
    authors: [{ name: "Steven (Jiaxun) Tang", isMe: true }],
    links: {},
    publisher: "Amateur Project",
  }
  
]);
const experiences = reactive([
  {
    position: "ML Engineer Intern",
    startDate: new Date("05/27/2025"),
    org: "Firefly @ Adobe",
    orgDescs: [
      {
        name: "Adobe",
        logo: require("@/assets/imgs/icon/Adobe.svg"),
        link: "https://www.adobe.com",
      },
    ],
    location: "San Jose, CA",
    jd: "TBA.",
  },
  {
    position: "Research Intern",
    startDate: new Date("02/26/2024"),
    endDate: new Date("08/09/2024"),
    org: "US Infrastructure System Lab @ Bytedance",
    orgDescs: [
      {
        name: "Bytedance",
        logo: require("@/assets/imgs/icon/Bytedance.svg"),
        link: "https://www.bytedance.com",
      },
    ],
    location: "San Jose, CA",
    jd: "Diagnosing GPU memory related perforamce problems with my research work MLInsight.",
  },
  {
    position: "Research Intern",
    startDate: new Date("05/30/2023"),
    endDate: new Date("11/10/2023"),
    org: "US Infrastructure System Lab @ Bytedance",
    orgDescs: [
      {
        name: "Bytedance",
        logo: require("@/assets/imgs/icon/Bytedance.svg"),
        link: "https://www.bytedance.com",
      },
    ],
    location: "San Jose, CA",
    jd: "Diagnosing GPU memory related perforamce problems with my research work MLInsight.",
    publisher: "In Progress......",
  },
  {
    position: "RA/TA",
    startDate: new Date("12/01/2020"),
    endDate: null,
    org: "Mass Lab @ UMass Amherst",
    orgDescs: [
      {
        name: "Mass Lab",
        logo: require("@/assets/imgs/icon/umass.png"),
        link: "https://utsasrg.github.io",
      },
      {
        name: "UMass Amherst",
        logo: require("@/assets/imgs/icon/umassnewlogo.svg"),
        link: "https://ece.umass.edu",
      },
    ],
    location: "Amherst, MA",
    jd: "Research training in the MLSys performance analysis and optimization area.",
  },
  {
    position: "Research Intern",
    startDate: new Date("09/01/2019"),
    endDate: new Date("01/01/2020"),
    org: "PeLab @ SJTU",
    orgDescs: [
      {
        name: "Pelab",
        logo: require("@/assets/imgs/icon/pelab.svg"),
        link: "https://pelab.sjtu.edu.cn",
      },
      {
        name: "SJTU",
        logo: require("@/assets/imgs/icon/sjtu.png"),
        link: "https://sjtu.edu.cn",
      },
    ],
    location: "Amherst, MA",
    jd: "Working on a machine learning and chemical engineering interdisciplinarity research project.",
  },
  {
    position: "RA",
    startDate: new Date("09/01/2016"),
    endDate: new Date("2019/02/01"),
    org: "IMC Lab @ SUES",
    orgDescs: [
      {
        name: "智能媒体计算Lab @ SUES",
        logo: require("@/assets/imgs/icon/SUES.svg"),
        link: "https://seee.sues.edu.cn",
      },
    ],
    location: "Amherst, MA",
    jd: "Attend innovation and entrepreneurship competitions and work on computer vision commercial projects.",
  },
  {
    position: "President of the SU",
    startDate: new Date("11/01/2014"),
    endDate: new Date("2015/11/01"),
    org: "SG High School",
    orgDescs: [
      {
        name: "SGZX",
        logo: require("@/assets/imgs/icon/sgzx.png"),
        link: "http://www.sgzx.shfxjy.cn/",
      },
    ],
    location: "Shanghai, CN",
    jd: "Student-elected president of the 46th student union. Led the digital transformation of the union. Co-chaired the student autonomous committee.",
  },
]);

const educations = reactive([
  {
    position: "Computer Engineering PhD",
    startDate: new Date("09/01/2021"),
    endDate: null,
    org: "COE @ UMASS Amherst",
    orgDescs: [
      {
        name: "UMASS Amherst",
        logo: require("@/assets/imgs/icon/umassnewlogo.svg"),
        link: "https://ece.umass.edu",
      },
    ],
  },
  {
    position: "Computer Science BE",
    startDate: new Date("09/01/2016"),
    endDate: new Date("06/01/2020"),
    org: "SEEE @ SUES",
    orgDescs: [
      {
        name: "SEEE@SUES",
        logo: require("@/assets/imgs/icon/SUES.svg"),
        link: "https://seee.sues.edu.cn",
      },
    ],
  },
]);

const awards = [
  "UMASS ECE department scholarship 23050 USD",
  "2020 Shanghai excellence college graduates honor",
  "First place (1/25 teams) in 2018 Cross-Strait College Students Innovation and Entrepreneurship Competition (1st participant)",
  "First place (1/675 teams) in 2018 Shanghai Computer Application Ability Competition, Smart City Group (1st participant)",
  "Silver award for 2018 “Chuang Qing Chun” Shanghai University Student Entrepreneurship Competition (2nd participant)",
  "First place in 2018 Xipu Enterprise Scholarship",
  "2017 Shanghai College Student Innovation and Entrepreneurship Training Program funding 9000 RMB (1st participant)",
];

const patents = [
  "63/281,942 Cacheperf patent (3rd inventor, in application)",
  "CN109032125A An computer vision navigation method of AGV (2nd inventor)",
  "CN109009074A Cardiac disease early warning device based on deep learning (4th inventor)",
];

// const skills = [
//   "System performance optimization",
//   "Binary analysis",
//   "Strong debugging ability",
//   "Strong code analysis ability",
//   "Famaliar with design pattern",
//   "Algotihtm design and implementation",
//   "System design",
//   "Engineering project management",
// ];

// const frameworks = [
//   "Tensorflow",
//   "Pytorch",
//   "OpenCV",
//   "Git",
//   "Android",
//   "CSS",
//   "Vue.js",
// ];

// const languages = ["C/C++", "Python", "Java"];
</script>


<style>
/* Include the global css */
@import "@/assets/css/materialcolor.css";
</style>

<style lang="scss" scoped>
.articleContainer {
  overflow: auto;
}
</style>

<style scoped lang="scss">
._category {
  text-align: left;
}

._header_container {
  align-items: center;
}

.experience_time_container {
  flex-grow: 1;
}
._avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
._me_content {
  max-width: min(570px * 1.8, 100%);
  width: min(570px * 1.8, 100%);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-direction: columns;
  align-self: center;
  text-align: left;
}

.self_Intro {
  hyphens: auto;
  text-align: justify;
  overflow-wrap: anywhere;
  // word-wrap: break-word;
}

.listItem {
  flex-grow: 0;
}

.experience_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 150px;
  column-gap: 0px;
  row-gap: var(--layout-margin);
  justify-content: space-between;
  align-items: center;
}

.project_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 150px;
  column-gap: var(--layout-margin);
  row-gap: var(--layout-margin);
  justify-content: space-between;
  align-items: center;
}

.tools_and_frameworks {
  // justify-content: start;
  row-gap: v-bind(--layout-margin);
  column-gap: v-bind(--layout-margin);
}
.tools_and_frameworks > div {
  flex-grow: 0;
  flex-shrink: 0;
  width: 80px;
  text-align: center;
}

.listCard {
  // border: 1px solid;
  // padding: var(--layout-margin);
  border-radius: 16px;
  display: flex;
  align-items: stretch;
  justify-items: stretch;
  justify-content: stretch;
}

.project_card {
  padding: var(--layout-margin);
  text-align: center;
  flex-grow: 1;
  // border: 1px solid;
  border-color: var(--md-sys-color-outline);
  border-radius: 16px;
  background-color: var(--md-sys-color-secondary-container);
  // border-top: 1px solid;

  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}
.project_card:hover {
  box-shadow: 0 0px 32px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.project_findmore_btn {
  padding: 16px;
  border: 1px solid;
  border-color: var(--md-sys-color-outline);
  border-radius: 16px;
}
</style>